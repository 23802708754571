@use 'common'

.wrapper
	//

.title
	font-size: 2.5rem
	margin-block: 0 1rem
	text-transform: uppercase

	@media not all and (min-width: common.$break37)
		text-align: center

.tiles
	width: 100%
	display: grid
	grid-template-columns: 1fr
	gap: .625rem

	@media (min-width: 21.875rem)
		grid-template-columns: 1fr 1fr

	@media (min-width: common.$break30)
		grid-template-columns: 1fr 1fr 1fr

	@media (min-width: common.$break48)
		grid-template-columns: 1fr 1fr
		gap: .8125rem

	@media (min-width: common.$break62)
		grid-template-columns: 1fr 1fr 1fr

	@media (min-width: common.$break75)
		grid-template-columns: 1fr 1fr 1fr 1fr
