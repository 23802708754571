@use 'common'

$transitionDuration: .5s

.wrapper
	width: 100%
	height: 100%
	border-radius: 1.875rem
	outline: .1875rem solid rgba(common.$color-black, 0.1)
	overflow: hidden
	transform: translateZ(0)

.link
	color: inherit

	&:hover, &:focus-visible
		.main
			&::before
				visibility: initial
				opacity: 1

		.image
			&:not(.is_video)
				transform: scale(1.03)

.main
	position: relative
	background-color: common.$color-grey
	aspect-ratio: 1 / 2
	z-index: 0

	&::before
		content: ''
		position: absolute
		inset: 0
		z-index: 2
		background-color: rgba(common.$color-black, 0.3)
		transition-property: opacity, visibility
		transition-duration: $transitionDuration
		opacity: 0
		visibility: hidden

	&::after
		content: ''
		position: absolute
		inset: 0
		background: linear-gradient(0deg, rgba(common.$color-black, 0.6) 0%, rgba(common.$color-black, 0) 62.05%)
		z-index: 1

.image
	object-fit: cover
	transition: transform $transitionDuration
	z-index: 0

.video
	aspect-ratio: 1 / 2
	width: 100%
	position: absolute
	inset: 0
	object-fit: cover
	z-index: 1

.content
	position: absolute
	inset: auto 1.25rem 1.25rem
	z-index: 2

.title
	font-size: 1.25em
	line-height: 1.25
	margin-block: unset
	font-weight: 500

	@media (min-width: common.$break48)
		font-size: 1.625em
