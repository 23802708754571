@use 'common'

.wrapper
	padding-bottom: var(--content-gap-small)

	.is_desktop
		@media not all and (min-width: common.$break48)
			display: none

.main
	@media (min-width: common.$break48)
		display: grid
		grid-template-columns: 18.75rem 1fr
		gap: 2.8125rem

.header
	$header: &

	max-width: var(--common-content-width-small)
	margin-bottom: 1rem

	&Wrapper
		&Title
			display: flex
			align-items: center
			gap: .625rem

	&Icon
		max-width: 2.5rem
		flex-grow: 1

		@media (min-width: common.$break48)
			max-width: 3.125rem

	&Title
		font-size: 1.875em
		font-weight: 500
		margin: unset

		@media (min-width: common.$break48)
			font-weight: 700
			font-size: 2.5em
			text-transform: uppercase

	&Description
		margin-top: .625rem
		margin-bottom: unset

	&.is_mobile
		@media not all and (min-width: common.$break48)
			.side
				text-align: center

		@media (min-width: common.$break48)
			display: none

.side
	max-width: 21.875rem
	width: 100%
	margin-inline: auto

	@media not all and (min-width: common.$break48)
		margin-top: 1.875rem

.posts
	@media not all and (min-width: common.$break48)
		margin-top: 1.25rem
