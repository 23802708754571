@use 'common'
@use 'sass:math'

$-break-aspectRatio: common.$break30
$-break-limit: common.$break125
$-hero-aspectRatio: math.div(1440, 600)
$-hero-maxWidth: 120rem

.hero
  --PostHero-hero-blockSpacing: 1.25rem
  @media (min-width: common.$break48)
    --PostHero-hero-blockSpacing: 3.125rem

  width: 100%
  position: relative
  padding-block: var(--PostHero-hero-blockSpacing)
  z-index: 0

  @media not all and (min-width: common.$break30)
    height: 100vh
    height: 100dvmax

  @media (min-width: $-break-aspectRatio)
    aspect-ratio: $-hero-aspectRatio

  @media (min-width: $-break-limit)
    width: $-hero-maxWidth
    margin-inline: auto

  &Background
    background-color: common.$color-grey
    position: absolute
    inset: 0
    z-index: 0
    overflow: hidden

    &In
      width: 100%
      height: 100%
      position: relative
      z-index: 0

      &::after
        content: ''
        width: 100%
        height: 100%
        position: absolute
        inset: 0
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.6) 22.62%, rgba(0, 0, 0, 0) 49.77%)
        z-index: 0

  &Image
    object-fit: cover

  &Main
    max-width: calc(var(--common-content-width-wide) + 2 * var(--common-edge-gap))
    width: 100%
    min-height: 100%
    margin-inline: auto
    padding-inline: var(--common-edge-gap)
    display: flex
    flex-direction: column
    justify-content: space-between
    position: relative
    z-index: 0

  &Content
    max-width: var(--common-content-width-normal)
    width: 100%
    margin-inline: auto

    &In
      display: flex
      flex-direction: column
      gap: 1.0625rem
      max-width: 30.625rem
      width: 100%

  &Title
    margin: unset
    font-size: 2.1875em
    line-height: 1.233

    @media (min-width: common.$break48)
      font-size: 3.75em

  &Lead
    p
      margin: unset
      font-size: 1.25em
      line-height: 1.25

.backLink
  display: inline-block
  margin-bottom: 5rem
  padding-top: calc(var(--Layout-header-height, 103px) - var(--PostHero-hero-blockSpacing))
  font-weight: 500

  &In
    display: flex
    line-height: 1
    align-items: center
    gap: 1em
    text-decoration: unset
    color: common.$color-white

    &:hover, &:focus-visible
      .backLinkIcon
        transform: translateX(-0.1875rem)

  &Icon
    display: flex
    transition: transform .2s
