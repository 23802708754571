@use 'common'

$activeFontWeight: 700

.wrapper
	&.is_active
		.link
			font-weight: $activeFontWeight

.link
	text-decoration: unset
	color: common.$color-white
	display: inline-block
	width: 100%
	padding: 1.25rem 1.5625rem
	background-color: common.$color-grey
	border-radius: common.$linkList-borderRadius
	font-weight: 500
	font-size: 1.25em
	line-height: 1.25
	letter-spacing: 0.2px // bold text causes breaking text on the next line (in edge cases) when it's active so the letter-spacing is solves it
