@use 'common'

$itemGap: .375rem

.wrapper
	//

.main
	position: relative

	.isHidden
		opacity: 0

.list
	list-style-type: none
	padding: unset
	margin: unset
	display: flex
	flex-direction: column
	gap: $itemGap
	position: relative
	z-index: 1

	@media (min-width: common.$break48)
		&.is_mobile
			display: none

	@media not all and (min-width: common.$break48)
		&.is_mobile
			position: absolute
			left: 0
			right: 0
			top: 0
			pointer-events: none

			&.is_selectOpen
				pointer-events: initial

				&::before
					content: ''
					position: absolute
					inset: -8px
					background-color: #000
					z-index: -1
					border-radius: 1.875rem

		&.is_desktop
			display: none
