@use 'common'
@use 'sass:math'

$-break-aspectRatio: common.$break30
$-break-limit: common.$break125
$-hero-aspectRatio: math.div(1440, 600)
$-hero-maxWidth: 120rem

.wrapper
	--PostPage-hero-blockSpacing: 1.25rem
	--PostPage-content-topSpacing: .9375rem

	&.is_otherPosts
		padding-bottom: var(--content-gap-default)

	@media (min-width: common.$break48)
		--PostPage-hero-blockSpacing: 3.125rem

	@media (min-width: common.$break75)
		--PostPage-content-topSpacing: 3.125rem

.hero
	width: 100%
	position: relative
	padding-block: var(--PostPage-hero-blockSpacing)
	z-index: 0

	@media not all and (min-width: common.$break30)
		height: 100vh
		height: 100dvmax

	@media (min-width: $-break-aspectRatio)
		aspect-ratio: $-hero-aspectRatio

	@media (min-width: $-break-limit)
		width: $-hero-maxWidth
		margin-inline: auto

	&Background
		background-color: common.$color-grey
		position: absolute
		inset: 0
		z-index: 0
		overflow: hidden

		&In
			width: 100%
			height: 100%
			position: relative
			z-index: 0

			&::after
				content: ''
				width: 100%
				height: 100%
				position: absolute
				inset: 0
				background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.6) 22.62%, rgba(0, 0, 0, 0) 49.77%)
				z-index: 0

	&Image
		object-fit: cover

	&Main
		max-width: calc(var(--common-content-width-wide) + 2 * var(--common-edge-gap))
		width: 100%
		min-height: 100%
		margin-inline: auto
		padding-inline: var(--common-edge-gap)
		display: flex
		flex-direction: column
		justify-content: space-between
		position: relative
		z-index: 0

	&Content
		max-width: var(--common-content-width-normal)
		width: 100%
		margin-inline: auto

		&In
			display: flex
			flex-direction: column
			gap: 1.0625rem
			max-width: 30.625rem
			width: 100%

	&Title
		margin: unset
		font-size: 2.1875em
		line-height: 1.233

		@media (min-width: common.$break48)
			font-size: 3.75em

	&Lead
		p
			margin: unset
			font-size: 1.25em
			line-height: 1.25

.backLink
	display: inline-block
	margin-bottom: 5rem
	padding-top: calc(var(--Layout-header-height, 103px) - var(--PostPage-hero-blockSpacing))
	font-weight: 500

	&In
		display: flex
		line-height: 1
		align-items: center
		gap: 1em
		text-decoration: unset
		color: common.$color-white

		&:hover, &:focus-visible
			.backLinkIcon
				transform: translateX(-0.1875rem)

	&Icon
		display: flex
		transition: transform .2s

.app
	&Title
		margin-block: 0 .5rem

	&Buttons
		background-color: var(--PostPage-hero-backgroundColor)

		&In
			padding-block: 1.25rem
			display: flex
			flex-direction: column
			align-items: center

		@media (min-width: common.$break75)
			display: none

.content
	display: grid
	grid-template-columns: 1fr minmax(auto, calc(var(--common-content-width-small) + 2 * var(--common-edge-gap))) 1fr

	&Main
		max-width: calc(var(--common-content-width-small) + 2 * var(--common-edge-gap))
		width: 100%
		padding-inline: var(--common-edge-gap)
		margin-top: var(--PostPage-content-topSpacing)

	&Side
		height: calc(100% - var(--content-gap-default))

.carousel
	&Title
		font-size: 2.5rem
		margin-block: 0 1rem
		text-transform: uppercase

.banner
	--PostPage-offset: 0
	padding-right: var(--common-edge-gap)
	margin-top: calc(-1 * var(--PostPage-offset) + var(--Layout-header-height))
	position: sticky
	top: 1.875rem

	@media not all and (min-width: common.$break75)
		display: none

	@media (min-width: 84.375rem)
		margin-left: 1.875rem

	@media (min-width: $-break-aspectRatio)
		$scrollBar-width-estimate: 2rem
		--PostPage-offset: #{math.div(100vw, $-hero-aspectRatio)} + #{$scrollBar-width-estimate}

	@media (min-width: $-break-limit)
		--PostPage-offset: #{math.div($-hero-maxWidth, $-hero-aspectRatio)}
