@use 'common'

.wrapper
	padding-top: calc(var(--content-gap-default) - var(--header-vertical-spacing))

	.is_typeAbout
		@media (min-width: common.$break48)
			display: flex
			gap: 50px

		.aside
			max-width: 19.0625rem
			width: 100%
			position: relative
			z-index: 1

			@media not all and (min-width: common.$break48)
				margin-inline: auto

			@media (min-width: common.$break48)
				padding-bottom: var(--content-gap-default)

		.group
			&:not(:first-child)
				@media (min-width: common.$break48)
					margin-top: 1.375rem

			&.is_mobile
				@media (min-width: common.$break48)
					display: none

			&.is_desktop
				@media not all and (min-width: common.$break48)
					display: none

		.content
			@media not all and (min-width: common.$break48)
				margin-top: var(--content-gap-small)

.content
	flex-grow: 1
