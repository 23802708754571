@use 'common'
@use 'sass:color'

.wrapper

	&.is_transparent
		.main
			background-color: transparent
			padding: 0

	&:not(&.is_transparent).is_active
		.main
			background-color: var(--ListItemContent-color, color.adjust(common.$color-grey, $lightness: 30%))

.link
	color: common.$color-white
	text-decoration: unset

.main
	display: flex
	align-items: center
	gap: .625rem
	background-color: common.$color-grey
	padding: .5313rem .625rem
	border-radius: common.$linkList-borderRadius
	transition: background-color .2s

.icon
	width: 3rem
	height: 3rem
	flex-shrink: 0

.name
	font-size: 1.25em
	line-height: 1.25
	font-weight: 500
