@use 'common'

$halfGap: .4375rem

.wrapper
	display: flex
	position: relative
	z-index: 0

.tiles
	+common.hideHorizontalScrollbar
	display: flex
	width: 100%
	height: 100%
	flex-wrap: nowrap
	scroll-snap-type: x mandatory
	margin-inline: -$halfGap

.tile
	display: flex
	min-width: 80%
	flex-shrink: 0
	scroll-snap-align: start

	&In
		width: calc(100% - #{$halfGap * 2})
		margin-inline: auto

	@media (min-width: 23.75rem)
		min-width: 45%

	@media (min-width: common.$break48)
		min-width: 30%

	@media (min-width: common.$break62)
		min-width: 25%

.button
	position: absolute
	z-index: 0
	background: unset
	border: unset
	padding: 1.25rem
	font-size: 1.25rem

	&:disabled
		&:hover, &:focus-visible
			cursor: not-allowed

	&.view_previous
		right: 100%
		transform: translateY(-50%)

	&.view_next
		left: 100%
		transform: translateY(-50%) scaleX(-1)

	&.view_previous, &.view_next
		top: 50%

	@media not all and (min-width: 78.125rem)
		display: none
