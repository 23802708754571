@use 'common'

$itemGap: .375rem

.list
	list-style-type: none
	padding: unset
	margin: unset
	display: flex
	flex-direction: column
	gap: $itemGap
	position: relative
	z-index: 1

	&.is_desktop
		@media (min-width: common.$break48)
			display: none

.item

	&.is_mobile

		&:first-child
			&::before
				border-top-left-radius: common.$linkList-borderRadius
				border-top-right-radius: common.$linkList-borderRadius

		&:last-child
			&::before
				border-bottom-left-radius: common.$linkList-borderRadius
				border-bottom-right-radius: common.$linkList-borderRadius

		&:not(.is_active)
			visibility: hidden
			opacity: 0

		&.is_visible
			visibility: initial
			opacity: 1

			&::before
				visibility: initial
				opacity: 1

			.polygon
				transform: rotate(180deg)
				transform-origin: center 25%

		@media (min-width: common.$break48)
			display: none

	@media not all and (min-width: common.$break48)
		&.is_desktop
			display: none

		&.is_active
			position: relative
			z-index: 1

.polygon
	position: absolute
	right: 1.875rem
	top: 50%
	transform: translateY(-50%)
	width: 0
	height: 0
	border-top: 14px solid common.$color-white
	border-left: 7px solid transparent
	border-right: 7px solid transparent
	transition: transform .3s
	pointer-events: none
